/* TODO: This should be migrated to next/font module when babel is removed */

/*
 * Fonts downloaded from https://gwfh.mranftl.com/fonts
 */

/* noto-serif-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/families/noto-serif-v23-latin-700.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* noto-serif-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 900;
  src: url('/static/fonts/families/noto-serif-v23-latin-900.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* rubik-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('/static/fonts/families/rubik-v28-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* rubik-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/families/rubik-v28-latin-regular.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* rubik-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/families/rubik-v28-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

:root {
  --rubik: 'Rubik', sans-serif;
  --noto-serif: 'Noto Serif', serif;
}
